import React from "react";

const Rolletball = () => {
  return (
    <>
      <p id="1_rotate" className="absolute !h-[50%] !w-[50%]  hidden">
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0  "></p>
      </p>
      <p
        id="20_rotate"
        className=" rotate-[9deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="14_rotate"
        className=" rotate-[19deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="31_rotate"
        className=" rotate-[28deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="9_rotate"
        className=" rotate-[39deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="22_rotate"
        className=" rotate-[49deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="18_rotate"
        className=" rotate-[59deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="29_rotate"
        className=" rotate-[69deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="7_rotate"
        className=" rotate-[78deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="28_rotate"
        className=" rotate-[88deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="12_rotate"
        className=" rotate-[98deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="35_rotate"
        className=" rotate-[108deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="3_rotate"
        className=" rotate-[118deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="26_rotate"
        className=" rotate-[128deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="0_rotate"
        className=" rotate-[137deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="32_rotate"
        className=" rotate-[147deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="15_rotate"
        className=" rotate-[157deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="19_rotate"
        className=" rotate-[167deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="4_rotate"
        className=" rotate-[176deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="21_rotate"
        className=" rotate-[186deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="2_rotate"
        className=" rotate-[196deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="25_rotate"
        className=" rotate-[206deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="17_rotate"
        className=" rotate-[215deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="34_rotate"
        className=" rotate-[225deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="6_rotate"
        className=" rotate-[235deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="27_rotate"
        className=" rotate-[245deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="13_rotate"
        className=" rotate-[255deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="36_rotate"
        className=" rotate-[264deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="11_rotate"
        className=" rotate-[274deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="30_rotate"
        className=" rotate-[283deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="8_rotate"
        className=" rotate-[293deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="23_rotate"
        className=" rotate-[302deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="10_rotate"
        className=" rotate-[312deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="5_rotate"
        className=" rotate-[322deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="24_rotate"
        className=" rotate-[331deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="16_rotate"
        className=" rotate-[340deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
      <p
        id="33_rotate"
        className=" rotate-[350deg] absolute !h-[50%] !w-[50%]  hidden "
      >
        <p className="!bg-white !h-2 !w-2 !rounded-full !absolute !bottom-0 !left-0 "></p>
      </p>
    </>
  );
};

export default Rolletball;
